<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group
              id="input-group-equipment"
              label="Комплектация"
              label-for="input-equipment"
            >
              <b-form-input
                id="input-equipment"
                v-model.trim="f.equipment"
                placeholder="Комплектация"
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group
              id="input--name"
              label="Название"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model.trim="f.name"
                placeholder="Название"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      f: {
        equipment: this.value?.equipment,
        name: this.value?.name,
      },
    };
  },

  watch: {
    f: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
