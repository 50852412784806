<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      item-key="name"
      class="elevation-1 row-links"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editItem.marketing_name }}</h5>
      </template>

      <model-form v-model="editItem" ref="modelForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {
  GET_ITEM,
  GET_ITEM_EQUIPMENTS,
} from '@/core/services/store/models.module';
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      modalShowEquipments: false,
      editItem: null,
      headers: [
        { text: 'Комплектация', value: 'equipment', sortable: true },
        { text: 'Название', value: 'name', sortable: true },
      ],
    };
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    async openEquipments(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM_EQUIPMENTS, oid);
      this.modalShowEquipments = true;
      return false;
    },

    saveForm() {
      this.$refs?.modelForm?.submit();
    },
  },
};
</script>

<style lang="scss"></style>
