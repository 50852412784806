<template>
  <div class="list-filter">
    <div class="bg-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-3">
            <b-form-group
              id="input-group-brand"
              label="Название"
              label-for="input-brand"
            >
              <b-form-input
                id="input-brand"
                v-model.trim="f.brand"
                placeholder="Марка"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-model"
              label="Семейство"
              label-for="input-model"
            >
              <b-form-input
                id="input-model"
                v-model.trim="f.model"
                placeholder="Семейство"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-marketing-name"
              label="Маркетинговое название"
              label-for="input-marketing-name"
            >
              <b-form-input
                id="input-marketing-name"
                v-model.trim="f.marketing_name"
                placeholder="Маркетинговое название"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-md-3">
            <b-form-group
              id="input-group-group"
              label="Группа"
              label-for="input-group"
            >
              <b-form-input
                id="input-group"
                v-model.trim="f.group"
                placeholder="Группа"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex row-switches flex-wrap">
        <switch-checkbox v-model="f.is_using" label="Используется" />

        <switch-checkbox v-model="f.is_published" label="Опубликовано" />

        <switch-checkbox v-model="f.is_commercial_transport" label="Комтранс" />

        <switch-checkbox v-model="f.has_photo" label="Фото" />

        <switch-checkbox v-model="f.has_video_review" label="Видеообзор" />

        <switch-checkbox
          v-model="f.has_video_instruction"
          label="Видеоинструкция"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FiltersMixin from './FiltersMixin';
import SwitchCheckbox from '../SwitchCheckbox.vue';
export default {
  components: { SwitchCheckbox },
  mixins: [FiltersMixin],
  data() {
    return {
      f: {
        brand: this.$route.query?.brand,
        model: this.$route.query?.model,
        group: this.$route.query?.group,
        marketing_name: this.$route.query?.marketing_name,
        is_using: this.$route.query?.is_using === 'true' || false,
        is_published: this.$route.query?.is_published === 'true' || false,
        is_commercial_transport:
          this.$route.query?.is_commercial_transport === 'true' || false,
        has_photo: this.$route.query?.has_photo === 'true' || false,
        has_video_review:
          this.$route.query?.has_video_review === 'true' || false,
        has_video_instruction:
          this.$route.query?.has_video_instruction === 'true' || false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.list-filter {
  margin-bottom: 25px;

  .row-switches {
    @media (max-width: 658px) {
      flex-direction: column;
    }
    .custom-switch:not(:first-child) {
      @media (min-width: 659px) {
        margin-left: 25px;
      }
    }
  }
}
</style>
