<template>
  <div v-if="form !== null" class="auto-dealer-form">
    <v-tabs background-color="white" color="deep-purple accent-4">
      <v-tab>Основная информация</v-tab>
      <v-tab-item>
        <div class="container-fluid pt-4">
          <div class="form-subheader">Сведения</div>
          <b-form-group id="input-group-0" label="OID" label-for="input-0">
            <b-form-input
              id="input-0"
              :value="form.oid"
              placeholder="OID"
              disabled
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Семейство"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="form.model"
              placeholder="Семейство"
              disabled
            ></b-form-input>
          </b-form-group>

          <!--<b-form-group id="input-group-2" label="Имя" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Имя"
              disabled
            ></b-form-input>
          </b-form-group>-->

          <div class="form-subheader">Редактируемые</div>
          <b-form>
            <b-form-group id="input-group-1" label="Марка" label-for="input-1">
              <b-form-select
                v-model="form.brand_oid"
                :options="brandOptions"
                id="input-1"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Маркетинговое название"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="form.marketing_name"
                placeholder="Маркетинговое название"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Группа" label-for="input-4">
              <b-form-select
                v-model="form.group_id"
                :options="groupOptions"
                id="input-4"
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-5">
              <switch-checkbox v-model="form.is_using" label="Используется" />
            </b-form-group>

            <b-form-group id="input-group-6">
              <switch-checkbox
                v-model="form.is_commercial_transport"
                label="Комтранс"
              />
            </b-form-group>

            <b-form-group
              id="input-group-7"
              label="Видеообзор"
              label-for="input-7"
            >
              <b-form-input
                id="input-7"
                v-model="form.video_review"
                placeholder="Видеообзор"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-8"
              label="Видеоинструкция"
              label-for="input-8"
            >
              <b-form-input
                id="input-8"
                v-model="form.video_instruction"
                placeholder="Видеоинструкция"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-9" label="Фото" label-for="input-9">
              <upload-file
                id="inpit-9"
                v-model="form.photo"
                :options="photoOptions"
              />
            </b-form-group>
          </b-form>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import SwitchCheckbox from '../../content/SwitchCheckbox';
import UploadFile from '@/view/content/widgets/FileDrop';
import Swal from 'sweetalert2';
import { validationMixin } from 'vuelidate';
import { email, required, minLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  components: { UploadFile, SwitchCheckbox },

  mixins: [validationMixin],

  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      form: this.value,
      modalShow: false,
      photoOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 200,
        maxFilesize: 20,
        maxFiles: 1,
        acceptedFiles: 'image/*',
      },
    };
  },

  computed: {
    ...mapGetters(['brands', 'groups']),
    brandOptions() {
      return this.brands.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },

    groupOptions() {
      return this.groups.map((x) => {
        return { text: x.title, value: x.oid };
      });
    },
  },

  validations: {
    form: {
      username: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
    },
  },

  watch: {
    form: {
      handler(v) {
        this.$emit('input', v);
      },
      deep: true,
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    submit() {
      this.$v.form.$touch();
      /* if (this.$v.form.$anyError) {
        submitButton[0].classList.remove(
          'spinner',
          'spinner-light',
          'spinner-right'
        );
        return;
      } */

      Swal.fire({
        title: '',
        text: 'Сохранено!',
        icon: 'success',
        heightAuto: false,
      });
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
