<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      item-key="name"
      class="elevation-1 row-links"
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.is_using="{ item }">
        <yes-no-badge :active="item.is_using" />
      </template>

      <template #item.is_published="{ item }">
        <yes-no-badge :active="item.is_published" />
      </template>

      <template #item.has_photo="{ item }">
        <yes-no-badge :active="item.has_photo" />
      </template>

      <template #item.has_video_review="{ item }">
        <yes-no-badge :active="item.has_video_review" />
      </template>

      <template #item.has_video_instruction="{ item }">
        <yes-no-badge :active="item.has_video_instruction" />
      </template>

      <template #item.is_commercial_transport="{ item }">
        <yes-no-badge :active="item.is_commercial_transport" />
      </template>

      <template #item.oid="{ item }">
        <div class="actions d-flex">
          <router-link
            v-if="buttons.includes('equipments')"
            to="#"
            title="Комплектации"
            event=""
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            @click.native.prevent="openEquipments(item.oid)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/Car.svg"></inline-svg>
            </span>
          </router-link>

          <router-link
            v-if="buttons.includes('model')"
            :to="{ name: urlName, params: { id: item.oid } }"
            title="Редактировать"
            event=""
            class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
            @click.native.prevent="clickRow(item.oid)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/media/svg/icons/General/Edit.svg"></inline-svg>
            </span>
          </router-link>
        </div>
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>

    <b-modal v-model="modalShow" scrollable size="xl" @ok="saveForm">
      <template #modal-title>
        <h5>Редактирование {{ editItem.marketing_name }}</h5>
      </template>

      <model-form v-model="editItem" ref="modelForm" />

      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="success" @click="ok()">
          Сохранить
        </b-button>
        <b-button size="sm" variant="danger" @click="cancel()">
          Отмена
        </b-button>
      </template>
    </b-modal>

    <model-equipment-modal
      v-model="modalShowEquipments"
      :modelId="modelId"
      :header="equipmentsModalHeader"
    />
  </div>
</template>

<script>
import YesNoBadge from '../YesNoBadge';
import {
  GET_ITEM,
  GET_ITEM_EQUIPMENTS,
} from '@/core/services/store/models.module';
import ModelForm from '../forms/ModelForm.vue';
import ModelEquipmentModal from '../modals/ModelEquipmentModal.vue';
export default {
  components: { YesNoBadge, ModelForm, ModelEquipmentModal },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },

    buttons: {
      type: [Array, String],
      default: () => 'model',
    },
  },

  data() {
    return {
      modalShow: false,
      modalShowEquipments: false,
      model: null,
      editItem: null,
      headers: [
        { text: 'Марка', value: 'brand', sortable: true },
        { text: 'Семейство', value: 'model', sortable: true },
        {
          text: 'Маркетинговое название',
          value: 'marketing_name',
          sortable: true,
        },
        {
          text: 'Группа',
          value: 'group',
          sortable: true,
        },
        { text: 'Используется', value: 'is_using', sortable: true },
        { text: 'Опубликовано', value: 'is_published', sortable: true },
        { text: 'Комтранс', value: 'is_commercial_transport', sortable: true },
        { text: 'Фото', value: 'has_photo', sortable: true },
        { text: 'Видеообзор', value: 'has_video_review', sortable: true },
        {
          text: 'Видеоинструкция',
          value: 'has_video_instruction',
          sortable: true,
        },
        { text: 'Действия', sortable: false, value: 'oid' },
      ],
    };
  },

  computed: {
    equipmentsModalHeader() {
      return this.model ? 'Комплектации - ' + this.model : '';
    },
    modelId() {
      return this.editItem?.oid;
    },
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  methods: {
    async clickRow(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.modalShow = true;
      return false;
    },

    async openEquipments(oid) {
      this.editItem = await this.$store.dispatch(GET_ITEM, oid);
      this.model = this.editItem.model;
      await this.$store.dispatch(GET_ITEM_EQUIPMENTS, oid);
      this.modalShowEquipments = true;
      return false;
    },

    saveForm() {
      this.$refs?.modelForm?.submit();
    },

    updateEquipment(v) {
      this.$emit('update-equipments', v);
    },
  },
};
</script>

<style lang="scss"></style>
