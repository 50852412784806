<template>
  <div>
    <b-modal v-model="modalShow" scrollable size="xl">
      <template #modal-title>
        <h5>{{ header }}</h5>
      </template>

      <model-equipments-filter v-model="equipmentsFilter" />

      <model-equipments-list :items="filterItems" />

      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="link" @click="cancel()">
          Закрыть
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="modalShowAddEquipment = true"
        >
          Добавить комплектацию
        </b-button>
      </template>
    </b-modal>

    <model-equipment-add-modal
      v-model="modalShowAddEquipment"
      @save-equipments="saveEquipments"
    />
  </div>
</template>

<script>
import ModelEquipmentsList from '../lists/ModelEquipmentsList.vue';
import ModelEquipmentsFilter from '../filters/ModelEquipmentsFilter.vue';
import ModelEquipmentAddModal from './ModelEquipmentAddModal.vue';
import { mapGetters } from 'vuex';
import {
  GET_ITEM_EQUIPMENTS,
  UPDATE_ITEM,
} from '@/core/services/store/models.module';

export default {
  components: {
    ModelEquipmentsFilter,
    ModelEquipmentsList,
    ModelEquipmentAddModal,
  },
  props: {
    header: {
      type: String,
      default: () => '',
    },
    value: {
      type: Boolean,
      default: () => false,
    },

    modelId: {
      type: [String, Number],
      default: () => 0,
    },
  },

  data() {
    return {
      modalShow: this.value,
      modalShowAddEquipment: false,
      equipmentsFilter: {
        equipment: '',
        name: '',
      },
    };
  },

  computed: {
    ...mapGetters(['getAllEquipments', 'getConnectedEquipments']),
    items() {
      return this.getConnectedEquipments;
    },

    filterItems() {
      const keys = Object.keys(this.equipmentsFilter);

      if (keys.length > 0) {
        const filteredArray = this.items
          .map((item) => {
            const newItem = {};
            keys.forEach((key) => {
              if (
                item[key]
                  .toString()
                  .toLowerCase()
                  .includes(this.equipmentsFilter[key].toString().toLowerCase())
              ) {
                newItem.oid = item.oid;
                newItem[key] = item[key];
              } else {
                newItem[key] = null;
              }
            });
            return newItem;
          })
          .filter((x) => Object.keys(x).length > 0)
          .filter((x) => {
            const exluded = [];

            Object.keys(x).forEach((key) => {
              exluded.push(x[key] === null);
            });
            return exluded.every((x) => x === false);
          });

        return this.items.filter((x) => {
          const oids = filteredArray.map((z) => z.oid);
          return oids.includes(x.oid);
        });
      }

      return this.items;
    },
  },

  watch: {
    value(v) {
      this.modalShow = v;
    },
    modalShow(v) {
      this.$emit('input', v);
    },
  },

  methods: {
    async saveEquipments(equipments) {
      await this.$store.dispatch(UPDATE_ITEM, {
        id: this.modelId,
        data: { equipments },
      });
      await this.$store.dispatch(GET_ITEM_EQUIPMENTS, this.modelId);
    },
  },
};
</script>

<style lang="scss" scoped></style>
