<template>
  <b-modal v-model="modalShow" scrollable size="xl" @ok="saveEquipments">
    <template #modal-title>
      <h5>Добавление комплектации</h5>
    </template>

    <model-equipments-filter v-model="equipmentsFilter" />

    <model-equipments-add-list
      :items="filterItems"
      @save-equipments="setEquipments"
    />

    <template #modal-footer="{ ok, cancel }">
      <b-button size="sm" variant="link" @click="cancel()"> Закрыть </b-button>
      <b-button size="sm" variant="success" @click.prevent="ok()">
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ModelEquipmentsAddList from '../lists/ModelEquipmentsAddList.vue';
import modelEquipmentsFilter from '../filters/ModelEquipmentsFilter.vue';
import { mapGetters } from 'vuex';
export default {
  components: { modelEquipmentsFilter, ModelEquipmentsAddList },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },

  data() {
    return {
      modalShow: this.value,
      equipments: {},
      equipmentsFilter: {
        equipment: '',
        name: '',
      },
    };
  },

  computed: {
    ...mapGetters(['getAllEquipments', 'getConnectedEquipments']),
    items() {
      return this.getAllEquipments;
    },

    filterItems() {
      const keys = Object.keys(this.equipmentsFilter);

      if (keys.length > 0) {
        const filteredArray = this.items
          .map((item) => {
            const newItem = {};
            keys.forEach((key) => {
              if (
                item[key]
                  .toString()
                  .toLowerCase()
                  .includes(this.equipmentsFilter[key].toString().toLowerCase())
              ) {
                newItem.oid = item.oid;
                newItem[key] = item[key];
              } else {
                newItem[key] = null;
              }
            });
            return newItem;
          })
          .filter((x) => Object.keys(x).length > 0)
          .filter((x) => {
            const exluded = [];

            Object.keys(x).forEach((key) => {
              exluded.push(x[key] === null);
            });
            return exluded.every((x) => x === false);
          });

        return this.items.filter((x) => {
          const oids = filteredArray.map((z) => z.oid);
          return oids.includes(x.oid);
        });
      }

      return this.items;
    },
  },

  watch: {
    value(v) {
      this.modalShow = v;
    },
    modalShow(v) {
      this.$emit('input', v);
    },
  },

  methods: {
    saveEquipments() {
      this.$emit('save-equipments', this.equipments);
    },

    setEquipments(v) {
      this.equipments = v.equipments;
    },
  },
};
</script>

<style lang="scss" scoped></style>
