<template>
  <div class="auto-dealer-table">
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="name"
      class="elevation-1 row-links"
      disable-pagination
      hide-default-footer
      :header-props="{ 'sort-icon': 'mdi-sort-variant' }"
    >
      <template #item.is_connected="{ item }">
        <switch-checkbox v-model="connectedItems[item.oid]" />
      </template>

      <template #no-data>
        <div>Не найдены записи</div>
      </template>

      <template #no-results>
        <div>Не найдены записи</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SwitchCheckbox from '../SwitchCheckbox.vue';
import { SET_CHANGE } from '@/core/services/store/models.module';
export default {
  components: { SwitchCheckbox },

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    urlName: {
      type: String,
      deafult: () => null,
    },
  },

  data() {
    return {
      modalShow: false,
      modalShowEquipments: false,
      editItem: null,
      connectedItems: {},
      headers: [
        { text: '', value: 'is_connected', sortable: false },
        { text: 'Марка', value: 'brand', sortable: true },
        { text: 'Мод. группа', value: 'mod_group', sortable: true },
        { text: 'Название', value: 'name', sortable: true },
        { text: 'КПП', value: 'code_kpp', sortable: true },
        { text: 'ДВС', value: 'engine_type', sortable: true },
        { text: 'Кузов', value: 'body', sortable: true },
      ],
    };
  },

  beforeMount() {
    this.$store.commit(SET_CHANGE, false);
    this.items.forEach((item) => {
      this.$set(this.connectedItems, item.oid, item.is_connected);
    });
  },

  mounted() {
    this.$root.$on('bv::modal::hidden', () => {
      this.editItem = null;
    });
  },

  watch: {
    connectedItems: {
      handler(v) {
        this.$emit('save-equipments', { equipments: v });
        this.$store.commit(SET_CHANGE, true);
      },

      deep: true,
    },
  },
};
</script>

<style lang="scss"></style>
